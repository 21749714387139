import TabView from "./TabView";
import FirebaseManager from "./FirebaseManager";

import DailyEdgeTab, { ShowScreen } from "./DailyEdgeTab";
import ScreenManager from "./ScreenManager";

import InvictusFactory from "./InvictusFactory";
import SettingsScreen from "./SettingsScreen";
import SubscriptionScreen from "./SubscriptionScreen";

import Loader from "./Loader";
import { ROUTES, SCREENS } from "./appConstants";

export default class App {
  static getInstance() {
    return App._instance;
  }
  constructor(useEmulators = null) {
    App._instance = this;
    if (useEmulators) {
      document.body.style.border = "5px solid red";
      document.body.style.boxSizing = "border-box";
    }

    this.factory = new InvictusFactory(this.firebaseManager);
    this.appData = this.factory.createAppData();
    this.user = this.factory.createUser();

    this.loader = new Loader(document.getElementById("loading-overlay"));
    this.appContainer = document.getElementById("app-container");
    this.authUI = document.getElementById("auth-ui");

    this.firebaseManager = new FirebaseManager(this, this.onAuthStateChanged.bind(this), useEmulators);
  }

  init() {
    this.firebaseManager.init();
  }

  onAuthStateChanged(authUserObj) {
    this.user.authUserObj = authUserObj;
    console.log("onAuthStateChanged called. user:" + authUserObj + this.constructor.name);
    this.user.authUserObj = authUserObj;
    if (authUserObj) {
      // User is signed in, load app content
      this.authUI.style.display = "none";
      this.appContainer.style.display = "block";
      this.loadApp();
    } else {
      // User is signed out, show the sign-in UI
      if (window.location.pathname != "/login") {
        window.location.replace("/login");
      } else {
        this.authUI.style.display = "block";
        this.appContainer.style.display = "none";
      }
    }
  }

  // first we need to get the authUserObj.
  // then we test of the user has a doc (ie we seen them beofre)
  // if no - then we need to wait and get it in a sec, we have a server-side listener on auth that if a new user is created, we create a doc for them
  // then we get the doc - now we have the user data
  async loadApp() {
    this.loader.showLoading();

    await this.user.load();
    await this.loadAppContent();
    this.dailyEdgeTab.data = await this.appData.dailyEdgeData.load();
    this.checkDeepLinking();
    // if (this.user.userData.subscriptionStatus === "active") {

    // } else {
    //   //this.openSubscribeScreen();
    // }
    this.loader.hideLoading();
  }

  async loadAppContent() {
    const response = await fetch("/html/app-content.html");
    const html = await response.text();
    this.appContainer.innerHTML = html;

    // managers
    this.screenManager = new ScreenManager();

    // main application TabView
    this.tabView = TabView.initById("tab-view");

    this.dailyEdgeShowScreen = new ShowScreen(document.getElementById("show-screen"));
    ScreenManager.getInstance().addScreen(this.dailyEdgeShowScreen);

    this.dailyEdgeTab = new DailyEdgeTab(document.getElementById("daily-edge-tab"), this.dailyEdgeShowScreen);

    this.tabView.domElement.addEventListener("change", () => {
      let tabName = this.tabView.selectedTabItem.name;
      if (this.screenManager.preventURLChange == false) {
        this.screenManager.changeWindowURL(tabName, tabName);
      }
    });
    this.initTabToAppMode();

    this.initScreens();

    this.initPulltoRefresh();
  }

  initTabToAppMode() {
    const tabBar = document.querySelector(".tab-bar");

    if (window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true) {
      tabBar.classList.add("tab-bar-standalone");
    } else {
      tabBar.classList.add("tab-bar-browser");
    }
  }

  initScreens() {
    const settingsScreen = new SettingsScreen(document.getElementById("settings-screen"), SCREENS.SETTINGS);
    this.screenManager.addScreen(settingsScreen);

    const subscriptionScreen = new SubscriptionScreen(document.getElementById("subscribe-screen"), SCREENS.SUBSCRIPTION);
    this.screenManager.addScreen(subscriptionScreen);

    document.getElementById("hamburger-menu").addEventListener("click", () => {
      this.screenManager.openScreenById(SCREENS.SETTINGS.id);
    });

    document.querySelectorAll(".close-button").forEach((button) => {
      button.addEventListener("click", (e) => {
        const targetScreenDomId = e.target.closest(".screen").id;
        const screen = ScreenManager.getInstance().getScreenByDomID(targetScreenDomId);
        this.screenManager.closeScreen(screen.id);
      });
    });
  }

  initPulltoRefresh() {
    document.addEventListener("DOMContentLoaded", function () {
      let startY;
      const refreshIndicator = document.getElementById("refresh-indicator");

      document.addEventListener(
        "touchstart",
        (e) => {
          if (document.scrollingElement.scrollTop === 0) {
            startY = e.touches[0].pageY;
          }
        },
        { passive: true }
      );

      document.addEventListener(
        "touchmove",
        (e) => {
          if (startY && document.scrollingElement.scrollTop === 0) {
            const distance = e.touches[0].pageY - startY;
            if (distance > 100) {
              refreshIndicator.classList.add("show");
            } else {
              refreshIndicator.classList.remove("show");
            }
          }
        },
        { passive: true }
      );

      document.addEventListener(
        "touchend",
        (e) => {
          if (refreshIndicator.classList.contains("show")) {
            refreshIndicator.classList.remove("show");
            location.reload(); // Refresh the page
          }
          startY = null;
        },
        { passive: true }
      );
    });
  }

  checkDeepLinking() {
    console.log("checkDeepLinking called location.pathname:" + location.pathname);
    const curPath = location.pathname; //.slice; //(1);
    this.handleRoute(curPath);
    this.screenManager.prevPath = "/";
  }

  handleRoute(curPath) {
    console.log("handleRoute handleRoute: currentPath:" + curPath);

    const routes = {
      [ROUTES.HOME]: () => {
        this.tabView.selectedTabName = ROUTES.DAILY_EDGE;
        this.screenManager.changeWindowURL(ROUTES.DAILY_EDGE, ROUTES.DAILY_EDGE);
      },
      [ROUTES.SETTINGS]: () => this.screenManager.openScreenById(SCREENS.SETTINGS.id),
      [ROUTES.DAILY_EDGE]: () => (this.tabView.selectedTabName = ROUTES.DAILY_EDGE),
      [ROUTES.TRADE_IDEAS]: () => (this.tabView.selectedTabName = ROUTES.TRADE_IDEAS),
      [ROUTES.MONTHLY_OUTLOOK]: () => (this.tabView.selectedTabName = ROUTES.MONTHLY_OUTLOOK),
      [ROUTES.LEARN]: () => (this.tabView.selectedTabName = ROUTES.LEARN),
    };

    if (routes[curPath]) {
      if (curPath != ROUTES.SETTINGS) {
        this.screenManager.quietCloseScreenById(SCREENS.SETTINGS.id);
      }
      console.log("calling route:" + curPath);
      this.screenManager.preventURLChange = true;
      routes[curPath](); // Call the function associated with this route
      this.screenManager.preventURLChange = false;
      return;
    }

    const match = curPath.match(/^\/daily-edge\/(\d{4}\.\d{2}\.\d{2})$/);
    console.log("match:" + match);

    if (match) {
      const dateStr = match[1]; // If we found a match, the dateStr will be in match[1]
      const show = this.appData.dailyEdgeData.getShowByDateStr(dateStr);
      if (show) {
        this.dailyEdgeTab.openShowScreen(show);
        return;
      }
    }

    // If we get here, we didn't match any of our routes. go home
    console.log("No route found for " + curPath);
    routes[ROUTES.HOME](); // Call the function associated with this route
  }
}
