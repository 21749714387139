import { initializeApp } from "firebase/app";

import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

import { getAuth, connectAuthEmulator, onAuthStateChanged } from "firebase/auth";
import { EmailAuthProvider, GoogleAuthProvider } from "firebase/auth"; //

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default class FirebaseManager {
  static getInstance() {
    if (!FirebaseManager._instance) {
      throw new Error("FirebaseManager not initialized");
    }
    return FirebaseManager._instance;
  }

  constructor(app, onAuthStateChangedHandler, useEmulators = null) {
    FirebaseManager._instance = this;

    this.useEmulators = useEmulators;

    this.firebaseConfig = {
      apiKey: "AIzaSyBXRZsBOq9KCmq66XDYYtpPKTeA8n32d_Y",
      authDomain: "auth.app.invictusresearch.ai",
      projectId: "invictus-research-ai",
      storageBucket: "invictus-research-ai.appspot.com",
      messagingSenderId: "43450052633",
      appId: "1:43450052633:web:025c3b14299e1f2dc5ba88",
      measurementId: "G-Z1KJX2V3D2",
    };

    this.app = app;
    this.firebaseApp = initializeApp(this.firebaseConfig);
    this.auth = getAuth(this.firebaseApp);
    this.firestore = getFirestore(this.firebaseApp);
    if (useEmulators && useEmulators.firestoreEmulator) {
      connectFirestoreEmulator(this.firestore, "localhost", 8088);
    }

    if (useEmulators && useEmulators.authEmulator) {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      console.log("connectAuthEmulator called");
    }

    this.storage = getStorage(this.firebaseApp);
    if (useEmulators && useEmulators.storageEmulator) {
      connectStorageEmulator(this.storage, "localhost", 9199);
      console.log("connectStorageEmulator called");
    }

    this.functions = getFunctions(this.firebaseApp);
    if (useEmulators && useEmulators.functionsEmulator) {
      connectFunctionsEmulator(this.functions, "localhost", 5001);
      console.log("connectFunctionsEmulator called");
    }

    // Listen for changes in user authentication state
    onAuthStateChanged(this.auth, onAuthStateChangedHandler);

    // Create the Firebase UI instance
    this.ui = new firebaseui.auth.AuthUI(this.auth);
  }

  init() {
    console.log("FirebaseManager.init called");
    this.ui.start("#firebaseui-auth-container", {
      signInSuccessUrl: "/", // Redirect URL after successful sign-in
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
        },
      ],
    });
  }

  doLogout() {
    console.log("doLogout called");
    this.auth
      .signOut()
      .then(() => {
        console.log("User signed out.");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  }

  callFunction(functionName, data) {
    const callable = httpsCallable(this.functions, functionName);
    return callable(data);
  }
}
