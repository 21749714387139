export default class Loader {
  constructor(domElement) {
    this.domElement = domElement;
  }
  showLoading() {
    this.domElement.classList.add("overlay-visible");
  }

  hideLoading() {
    this.domElement.classList.remove("overlay-visible");
  }
}
