import { doc, getDoc } from "firebase/firestore";
import FirebaseManager from "./FirebaseManager";
import MyClientUtils from "./MyClientUtils.js";

export class InvictusUser {
  constructor(dataManager) {
    this.authUserObj = null;
    this.userData = null;
    this.dataManager = dataManager;
  }

  async load() {
    await this.dataManager.attemptLoading(5, 1000);
    return this;
  }
}

export class InvictusUserDataManager {
  constructor(userObj) {
    this.user = userObj;
  }

  async attemptLoading(times, delay) {
    console.log("attemptLoading called");
    const uid = this.user.authUserObj.uid;
    const userData = await MyClientUtils.retryUntilTrue(
      async () => {
        console.log("retryUntilTrue called");
        const userData = await this.loadByUID(uid);
        return userData;
      },
      times,
      delay
    );

    if (userData == null) {
      console.log("Problem fetching user data");
    }
    this.user.userData = userData;
    return userData;
  }

  async loadByUID(uid) {
    const userDocRef = doc(FirebaseManager.getInstance().firestore, "users", uid);

    const userDoc = await getDoc(userDocRef);
    return userDoc.data();
  }
}
