export class Screen {
  /**
   * @param {HTMLElement} domElement
   * @param {string} id
   * @param {string} path
   */
  constructor(domElement, id, path) {
    this.domElement = domElement;
    this.id = id;
    this.path = path;
  }
}

export default class ScreenManager {
  /**
   * @returns {ScreenManager}
   */
  static getInstance() {
    return ScreenManager._instance;
  }
  constructor() {
    ScreenManager._instance = this;

    this.prevPath = "/";
    this.preventURLChange = false;

    this.prevScreenId = null;
    this.curScreenId = null;

    this.screensMap = {};
    this.screenDomMap = {};
  }

  changeWindowURL(data, newPath) {
    console.log("changeWindowURL called with " + newPath);
    this.prevPath = window.location.pathname;
    console.log("history.pushState(data,  newPath);" + data + " " + newPath);
    history.pushState({ data: data }, "", newPath);
  }

  /**
   * @param {string} screenId
   **/
  closeScreen(screenId) {
    this.quietCloseScreenById(screenId);
    if (this.preventURLChange == false) {
      this.changeWindowURL(null, this.prevPath);
    }
  }

  /**
   * @param {Screen} screen
   * @param {string} id
   */
  addScreen(screen) {
    this.screensMap[screen.id] = screen;
    this.screenDomMap[screen.domElement.id] = screen;
  }

  /**
   * @param {string} domId
   * @returns {Screen}
   * */
  getScreenByDomID(domId) {
    return this.screenDomMap[domId];
  }

  /**
   * @param {string} id
   * @returns {Screen}
   */
  getScreen(id) {
    return this.screensMap[id];
  }

  quietCloseScreenById(screenId) {
    const screen = this.getScreen(screenId);
    if (screen.domElement.onBeforeClose) {
      screen.domElement.onBeforeClose();
    }

    screen.domElement.classList.remove("open");
    this.prevScreenId = screenId;
    this.curScreenId = null;
  }

  /**
   *
   * @param {string} screenId
   * @param {any} data
   * @returns
   */
  openScreenById(screenId, data) {
    if (this.curScreenId == screenId) {
      return;
    }

    const screen = this.getScreen(screenId);
    screen.domElement.classList.add("open");

    this.prevScreenId = this.curScreenId;
    this.curScreenId = screenId;
    if (this.preventURLChange == false) {
      this.changeWindowURL(data, screen.path);
    }
  }
}
