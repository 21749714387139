import { Screen } from "./ScreenManager";
import App from "./app";

export default class SubscriptionScreen extends Screen {
  constructor(domElement, { id, path }) {
    super(domElement, id, path);

    this.strypeBuyBtn = this.domElement.querySelector("#stipe-buy-button");
    this.strypeBuyBtn.setAttribute("customer-email", App.getInstance().user.authUserObj.email);
    this.strypeBuyBtn.setAttribute("client-reference-id", App.getInstance().user.authUserObj.uid);
  }
}
