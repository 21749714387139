class TabView {
  constructor(tabViewDomElement) {
    if (!(tabViewDomElement instanceof HTMLElement)) {
      throw new Error("TabView.init(): Argument must be an HTMLElement");
    }

    this.domElement = tabViewDomElement;
    this.tabItems = Array.from(this.domElement.querySelectorAll(".tab-item"));
    this.tabContentContainers = Array.from(this.domElement.querySelectorAll(".tab-content-container"));

    this._selectedTabIndex = -1;

    this.initializing = true;
    this.initialize();
    this.initializing = false;
  }

  static initById(id) {
    return new TabView(document.getElementById(id));
  }

  static init(tabView) {
    return new TabView(tabView);
  }

  showTab(index) {
    console.log("setTab called. _selectedTabIndex:" + this._selectedTabIndex + " setting to:" + index);
    if (this._selectedTabIndex === index) {
      return;
    }

    // Update selected state for tab content containers
    this.tabContentContainers.forEach((container, containerIndex) => {
      if (containerIndex === index) {
        container.classList.add("selected");
      } else {
        container.classList.remove("selected");
      }
    });

    // Update selected state for tab items
    this.tabItems.forEach((item, itemIndex) => {
      if (itemIndex === index) {
        item.classList.add("selected");
      } else {
        item.classList.remove("selected");
      }
    });

    this._selectedTabIndex = index;
    if (!this.initializing) {
      this.domElement.dispatchEvent(new Event("change"));
    }
  }

  initialize() {
    this.tabItems.forEach((tabItem, index) => {
      tabItem.name = tabItem.dataset.name;
      tabItem.addEventListener("click", () => {
        this.showTab(index);
      });
    });

    let initialTab = this.domElement.dataset.selectedTabIndex ? parseInt(this.domElement.dataset.selectedTabIndex, 10) : 0;
    this.showTab(initialTab);
  }

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(index) {
    if (index >= 0 && index < this.tabItems.length) {
      this.showTab(index);
    } else {
      console.error("Index out of bounds");
    }
  }

  set selectedTabName(name) {
    let index = this.tabItems.findIndex((item) => item.name === name);
    if (index !== -1) {
      this.showTab(index);
    } else {
      console.error("Tab name not found");
    }
  }

  get selectedTabItem() {
    return this.tabItems[this._selectedTabIndex];
  }

  get selectedTab() {
    return this.tabContentContainers[this._selectedTabIndex];
  }
}

export default TabView;
