import "../css/main.css";
import "../css/header.css";
import "../css/showCard.css";
import "../css/showScreen.css";
import "../css/screens.css";
import "../css/tab-view.css";

// my own amazing code:
import App from "./app";

let useEmulators = null;

/**
 * this gets set in the webpack.config.js file based on the NODE_ENV.
 * if it's development, we use the emulators.
 */
if (process.env.NODE_ENV === "development") {
  useEmulators = {
    authEmulator: true,
    functionsEmulator: true,
    storageEmulator: true,
    firestoreEmulator: true,
  };
}

const app = new App(useEmulators);
app.init();
