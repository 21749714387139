import FirebaseManager from "./FirebaseManager";
import ScreenManager, { Screen } from "./ScreenManager";
import App from "./app";
import { SCREENS } from "./appConstants";

export default class SettingsScreen extends Screen {
  constructor(domElement, { id, path }) {
    super(domElement, id, path);

    this.logoutBtn = this.domElement.querySelector("#logout-button");
    this.logoutBtn.addEventListener("click", () => {
      FirebaseManager.getInstance().doLogout();
      window.location.replace("/");
      window.location.reload(true);
    });

    this.subscriptionBtn = this.domElement.querySelector("#subscription-button");
    this.subscriptionBtn.addEventListener("click", () => {
      //ScreenManager.getInstance().openScreenById(SCREENS.SUBSCRIPTION.id);
    });

    this.userNameLabel = this.domElement.querySelector("#user-name-label");
    this.userNameLabel.innerText = App.getInstance().user.authUserObj.displayName;

    this.userEmailLabel = this.domElement.querySelector("#user-email-label");
    this.userEmailLabel.innerText = App.getInstance().user.authUserObj.email;
  }
}
