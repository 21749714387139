import { DailyEdgeData, DailyEdgeDataManager, InvictusAppData } from "./AppData";
import { InvictusUser, InvictusUserDataManager } from "./InvictusUser";

export default class InvictusFactory {
  constructor(firebaseManager) {
    this.firebaseManager = firebaseManager;
  }

  createAppData() {
    const appData = new InvictusAppData();
    appData.dailyEdgeData = new DailyEdgeData();
    const dailyEdgeDataManager = new DailyEdgeDataManager(appData.dailyEdgeData);
    return appData;
  }

  createUser() {
    const user = new InvictusUser();
    const userDataManager = new InvictusUserDataManager(user);
    user.dataManager = userDataManager;
    return user;
  }
}
