import { ref, getDownloadURL } from "firebase/storage";
import config from "./config.client";
import FirebaseManager from "./FirebaseManager";
import MyClientUtils from "./MyClientUtils";

export class InvictusAppData {
  constructor() {
    this.dailyEdgeData = new DailyEdgeData();
  }
}

export class DailyEdgeData {
  constructor() {
    /**
     * @type {DailyEdgeShow[]}
     */
    this.dailyEdgeShows = [];

    /**
     * @type {DailyEdgeDataManager}
     */
    this.dataManager = null;
  }

  /**
   *
   * @param {string} dateStr
   * @returns {DailyEdgeShow}
   */
  getShowByDateStr(dateStr) {
    return this.dailyEdgeShows.find((show) => show.dateStr === dateStr);
  }

  async load() {
    this.dailyEdgeShows = await this.dataManager.load();
    return this;
  }
}

export class DailyEdgeShow {
  constructor() {
    this.published = false;
    this.dateStr = "";
    this.title = "";
    this.length = "";
    this.videoURL = "";
    this.thumbURL = "";
    this.mikeThumbURL = "";
    this.script = "";
    this.definitions = [""];
    this.suggestedQuestions = [""];
    this.pipSide = "";
  }
}

export class DailyEdgeDataManager {
  /**
   *
   * @param {DailyEdgeData} dailyEdgeData
   */
  constructor(dailyEdgeData) {
    this.dailyEdgeData = dailyEdgeData;
    this.dailyEdgeData.dataManager = this;
  }

  /**
   *
   * @param {string} dateStr
   * @param {string} sizeStr
   * @returns
   */
  getThumbnailUrl(dateStr, sizeStr, folder) {
    const baseUrl = FirebaseManager.getInstance().useEmulators ? config.emulatorStorageBaseURL : config.firebaseStorageBaseURL;
    const fileName = sizeStr == null ? `${folder}/${dateStr}.png` : `${folder}/${dateStr}.${sizeStr}.jpg`;
    const url = `${baseUrl}${encodeURIComponent(fileName)}?alt=media`;
    return url;
  }

  /**
   *
   * @param {DailyEdgeShow[]} data
   */
  processData(data) {
    const shows = data; // later we'll have user data etc
    for (let show of shows) {
      let curThumbURl = show.thumbURL;
      let newThumbURL = this.getThumbnailUrl(show.dateStr, "400", "thumbs");
      show.old_thumbURL = curThumbURl;
      show.thumbURL = newThumbURL;

      let curMikeThumbURL = show.mikeThumbURL;
      let newMikeThumbURL = this.getThumbnailUrl(show.dateStr, "800", "mikes");
      show.old_mikeThumbURL = curMikeThumbURL;
      show.mikeThumbURL = newMikeThumbURL;

      let dateStr = show.dateStr.replace(/\./g, "-");
      let date = new Date(dateStr);
      let weekNumber = MyClientUtils.getWeekNumber(date);

      show.altWeek = weekNumber % 2 == 0;
    }
  }

  async load() {
    console.log("loadDailyEdgeShowsData called");

    try {
      const storage = FirebaseManager.getInstance().storage;
      const path = "/data/dailyEdgeShows.json";
      const storageRef = ref(storage, path);
      const url = await getDownloadURL(storageRef);

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      this.processData(data);
      this.dailyEdgeShows = data;

      return data;
    } catch (error) {
      console.error("Error in loadDailyEdgeShowsData:", error);
      throw error; // Re-throw the error after logging it
    }
  }
}
